import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"menu"} />
		<Helmet>
			<title>
				Kávészimfóniánk bemutatása a Perk Haven Caféban
			</title>
			<meta name={"description"} content={"Kóstolja meg a kávé dallamát - Fedezze fel harmonikus keverékünket a Perk Haven Caféban!"} />
			<meta property={"og:title"} content={"Kávészimfóniánk bemutatása a Perk Haven Caféban"} />
			<meta property={"og:description"} content={"Kóstolja meg a kávé dallamát - Fedezze fel harmonikus keverékünket a Perk Haven Caféban!"} />
			<meta property={"og:image"} content={"https://vorides.live/img/image-1.jpg"} />
			<link rel={"shortcut icon"} href={"https://vorides.live/img/135275.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://vorides.live/img/135275.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://vorides.live/img/135275.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://vorides.live/img/135275.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://vorides.live/img/135275.png"} />
			<meta name={"msapplication-TileImage"} content={"https://vorides.live/img/135275.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Menü
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				A Perk Haven Café nem csak egy kávézóhely - ez egy menedékhely azok számára, akik kivételes kávéélményre vágynak. Étlapunk gondosan összeállított, a különböző ízléseknek megfelelő kávé- és teakeverékek szimfóniáját kínálja. Kényeztesse magát elbűvölő választékunkkal, és hagyja, hogy minden egyes korty egy jegyzet legyen a kávés utazásán.
			</Text>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-3">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Text
						margin="0px 0px 20px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						height="400px"
						display="flex"
						align-items="flex-end"
						padding="0px 15px 35px 15px"
						background="linear-gradient(180deg,rgba(4, 8, 12, 0.35) 0%,--color-dark 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://vorides.live/img/image-5.jpg) 50% 0% /cover repeat scroll padding-box"
						color="--light"
						lg-height="300px"
						md-height="400px"
					>
						Főzött Elixírek
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
						- Eszpresszó klasszikusok: Merüljön el a szakértelemmel elkészített eszpresszó gazdag és erőteljes ízében, amely a kávérajongók időtlen klasszikusa.
						<br />
						<br />
						- Teakeverékek: Fedezze fel a teakeverékek válogatott választékát, a nyugtató kamillától az élénkítő zöld teáig, amely megnyugtató alternatívát kínál a tea szerelmeseinek.
						<br />
						<br />
						- Aláírt latték: A legfinomabb kávébabokat selymesen gőzölt tejjel kombináló, bársonyos élvezetet nyújtó signature lattéinkkel emeljük a kávéélményt.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text
						margin="0px 0px 20px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						height="400px"
						display="flex"
						align-items="flex-end"
						padding="0px 15px 35px 15px"
						background="linear-gradient(180deg,rgba(4, 8, 12, 0.35) 0%,--color-dark 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://vorides.live/img/image-6.jpg) 50% 0% /cover repeat scroll padding-box"
						color="--light"
						lg-height="300px"
						md-height="400px"
					>
						Édes szerenádok
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
						- Dekadens desszertek: Egészítse ki kávéutazását kényeztető desszertjeinkkel, amelyek mindegyike úgy készült, hogy harmonizáljon italaink változatos ízeivel.
						<br />
						<br />
						- Helyi finomságok: Fedezze fel a helyi forrásból származó finomságokat, amelyek egy kis közösségi ízzel gazdagítják kávészünetét.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Text
						margin="0px 0px 20px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						height="400px"
						display="flex"
						align-items="flex-end"
						padding="0px 15px 35px 15px"
						background="linear-gradient(180deg,rgba(4, 8, 12, 0.35) 0%,--color-dark 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://vorides.live/img/image-7.jpg) 50% 0% /cover repeat scroll padding-box"
						color="--light"
						lg-height="300px"
						md-height="400px"
					>
						Italpárosítási élmények
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
						- Kávé és desszert párosítás: Engedje meg baristáinknak, hogy végigvezessék Önt egy személyre szabott párosítási élményen, és a tökéletes desszertkísérővel fokozzák az ízek szimfóniáját.
					</Text>
					<Text
						margin="0px 0px 20px 0px"
						font="normal 500 28px/1.2 --fontFamily-sans"
						display="flex"
						align-items="flex-end"
						padding="30px 15px 15px 15px"
						color="--dark"
					>
						Diétás információk
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 20px/1.5 --fontFamily-sansHelvetica" color="#6a7179">
						Részletes diétás információkért, beleértve az allergéntartalmat és a különleges kéréseket, kérjük, lépjen kapcsolatba csapatunkkal. Elkötelezettek vagyunk a különböző igények figyelembevétele mellett, hogy mindenki élvezhesse a Perk Haven élményt.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				padding="0px 160px 0px 0px"
				border-radius="24px"
				margin="0px 0px 0px 0px"
				width="60%"
				lg-padding="0px 50px 0px 0px"
				lg-width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				md-margin="0px 0px 60px 0px"
				md-align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Kapcsolatfelvétel
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					font="--base"
					color="--greyD3"
					md-text-align="center"
					md-margin="0px 0px 20px 0px"
				>
					Akár kávérajongó, akár alkalmi kávézó, csapatunk készséggel áll rendelkezésére, hogy segítsen Önnek kiválasztani a hangulatának megfelelő tökéletes keveréket. Vegye fel velünk a kapcsolatot, ha további információkat szeretne kapni étlapunkról, vagy ha meg szeretné tervezni látogatását a Perk Haven Caféban.
				</Text>
				<Link
					href="/contacts"
					text-decoration-line="initial"
					color="--light"
					font="--lead"
					hover-opacity="0.9"
					background="--color-primary"
					border-radius="8px"
					padding="11px 24px 12px 24px"
				>
					Kapcsolatfelvétel
				</Link>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});